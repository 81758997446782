import { Switch } from "@headlessui/react"
import * as React from "react"
import { Controller, useForm } from "react-hook-form"
import { JoinBanner } from "../components/JoinBanner"
import { Layout } from "../components/Layout"
import Seo from "../components/seo"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const FieldError = ({ message }) => {
  if (message) {
    return <div className="mt-1 text-red-600">{message}</div>
  }

  return null
}

const Page = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const onSubmit = (data, e) => {
    e.target.submit()
  }
  return (
    <Layout>
      <Seo title="Suggest Feature" />
      <div className="bg-white px-4 overflow-hidden sm:px-6 lg:px-8 ">
        <JoinBanner />
        <div className="relative max-w-xl mx-auto pb-16  lg:pb-24">
          <svg
            className="absolute left-full transform translate-x-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <svg
            className="absolute right-full bottom-0 transform -translate-x-1/2"
            width={404}
            height={404}
            fill="none"
            viewBox="0 0 404 404"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="85737c0e-0916-41d7-917f-596dc7edfa27"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={404}
              fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
            />
          </svg>
          <div className="text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Join to the Early Founders
            </h2>
            <p className="mt-4 text-lg leading-6 text-gray-500"></p>
          </div>
          <div className="mt-12">
            <form
              id="form"
              action="https://formsubmit.co/06716c6773d92747caf65a4370235137"
              method="POST"
              className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                type="hidden"
                name="_next"
                value="https://barista.wpbusters.com/download"
              />
              <input
                type="hidden"
                name="_subject"
                value="[baristawp] Join to the Early Founders!"
              />
              <input type="text" name="_honey" style={{ display: "none" }} />
              <input type="hidden" name="_captcha" value="false" />
              <div className="sm:col-span-2">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                    {...register("name", {
                      required: "Enter your name.",
                      minLength: {
                        value: 2,
                        message: "Enter your name.",
                      },
                    })}
                  />
                  <FieldError message={errors.name?.message} />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                    {...register("email", {
                      required: "Enter your email.",
                      pattern: /^\S+@\S+$/i,
                    })}
                  />
                  <FieldError message={errors.email?.message} />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <Controller
                      render={({ field }) => {
                        return (
                          <Switch
                            name={field.name}
                            checked={!!field.value}
                            onChange={field.onChange}
                            className={classNames(
                              field.value ? "bg-blue-600" : "bg-gray-200",
                              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            )}
                          >
                            <span className="sr-only">Agree to policies</span>
                            <span
                              aria-hidden="true"
                              className={classNames(
                                field.value ? "translate-x-5" : "translate-x-0",
                                "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                              )}
                            />
                          </Switch>
                        )
                      }}
                      control={control}
                      name="agreed"
                      rules={{
                        required: "Please agree to the Terms and Conditions.",
                      }}
                      defaultValue={false}
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-base text-gray-500">
                      By selecting this, you agree to the{" "}
                      <a
                        href="/terms"
                        className="font-medium text-gray-700 underline"
                      >
                        Terms and Conditions
                      </a>{" "}
                      and{" "}
                      <a
                        href="/privacy"
                        className="font-medium text-gray-700 underline"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                    <FieldError message={errors.agreed?.message} />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-2">
                <button
                  type="submit"
                  className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Join and Get the Plugin
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
