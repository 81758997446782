import React from "react"
// sm:pb-12 lg:pb-24 lg:pb-32
export const JoinBanner = ({ joinButton }) => {
  return (
    <div className="relative max-w-7xl mx-auto p-4 sm:p-6 lg:p-8 ">
      <div className="bg-gradient-to-r from-orange-400 to-pink-500 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
        <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
          <div className="lg:self-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              <span className="block">Great! Ready to dive in?</span>
              <span className="block text-red-900">
                It's wonderful to see you among the Early Founders!
              </span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-orange-50">
              The plugin will be available soon, but ready to give it to you
              now, you are welcome for suggestions!
            </p>
            {joinButton && <a
              href="/join#form"
              className="mt-8 bg-orange-100 border border-transparent rounded-md shadow py-3 px-6 inline-flex items-center text-base font-medium text-red-600 hover:text-red-500"
            >
              Join and Get the Plugin
            </a>}
          </div>
        </div>
        <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
          <img
            className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
            src={require("./../images/window.png").default}
            alt="Window"
          />
        </div>
      </div>
    </div>
  )
}
